import React, { Component } from 'react';
import { Formik } from 'formik';
import Recaptcha from 'react-recaptcha';
import Page from 'components/page';
import { CONTACT_PAGE_TITLE, CONTACT_PAGE_DESCRIPTION } from 'utils/constants';
import Alert from 'components/alert';

class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = { formSubmitted: false };
  }
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  handleSubmit = (values, { resetForm }) => {
    return new Promise((resolve, reject) => {
      if (process.env.NODE_ENV === 'production') {
        fetch(process.env.REACT_APP_API_CONTACT_ENDPOINT, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow',
          referrer: 'no-referrer',
          body: JSON.stringify(values)
        })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        setTimeout(() => {
          resolve();
        }, 1000);
      }
    }).then(() => {
      this.setState({ formSubmitted: true });
      resetForm();
    });
  };

  render() {
    const { formSubmitted } = this.state;
    return (
      <Page title={CONTACT_PAGE_TITLE} description={CONTACT_PAGE_DESCRIPTION}>
        <h1>{CONTACT_PAGE_TITLE}</h1>
        <p>
          Whether you're looking to develop out a new site/application or just
          looking for someone to maintain your current ones - I'd love to hear
          about it. Let's talk how to actualize your ideas.
        </p>
        <br />
        <br />
        {formSubmitted && (
          <Alert icon="send">Thank you! Your message has been sent.</Alert>
        )}
        {!formSubmitted && (
          <Formik
            initialValues={{ name: '', email: '', message: '', recaptcha: '' }}
            validate={(values) => {
              let errors = {};

              if (!values.name) {
                errors.name = 'Required';
              }

              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }

              if (!values.message) {
                errors.message = 'Required';
              }

              if (!values.recaptcha) {
                errors.recaptcha = 'Required';
              }

              return errors;
            }}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <label htmlFor="name">Name</label>
                <input
                  disabled={isSubmitting}
                  id="name"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="email">Email</label>
                <input
                  disabled={isSubmitting}
                  id="email"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="message">Message</label>
                <textarea
                  disabled={isSubmitting}
                  id="message"
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                />
                <Recaptcha
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  render="explicit"
                  theme="dark"
                  verifyCallback={(response) => {
                    setFieldValue('recaptcha', response);
                  }}
                  onloadCallback={() => {}}
                />
                <button disabled={isSubmitting} type="submit">
                  Submit
                </button>
              </form>
            )}
          </Formik>
        )}
      </Page>
    );
  }
}

export default ContactPage;
