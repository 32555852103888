import React from 'react';
import styles from './equalizer.module.css';

function Equalizer(props) {
  const { active } = props;
  return (
    <div className={`${styles.wrapper} ${active ? styles.active : ''}`}>
      <div className={styles.column}>
        <div className={styles.bar} data-bar="1-1"></div>
        <div className={styles.bar} data-bar="1-2"></div>
      </div>
      <div className={styles.column}>
        <div className={styles.bar} data-bar="2-1"></div>
        <div className={styles.bar} data-bar="2-2"></div>
      </div>
      <div className={styles.column}>
        <div className={styles.bar} data-bar="3-1"></div>
        <div className={styles.bar} data-bar="3-2"></div>
      </div>
      <div className={styles.column}>
        <div className={styles.bar} data-bar="4-1"></div>
        <div className={styles.bar} data-bar="4-2"></div>
      </div>
    </div>
  );
}

export default Equalizer;
