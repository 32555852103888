import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/icon';
import Overlay from 'components/overlay';
import styles from './nav.module.css';

class Nav extends Component {
  static defaultProps = {
    isShown: false,
    onCloseComplete: () => {},
    onOpenComplete: () => {}
  };

  render() {
    const { isShown, onCloseComplete, onOpenComplete } = this.props;

    return (
      <Overlay
        isShown={isShown}
        onExited={onCloseComplete}
        onEntered={onOpenComplete}
      >
        {({ state, close }) => (
          <div
            className={styles['wrapper']}
            role="dialog"
            data-state={state}
            aria-labelledby="nav-title"
          >
            <h1 className={styles['title']} id="nav-title" tabIndex="-1">
              Site Navigation
            </h1>
            <div className={styles['header']}>
              <button
                className={styles['header-button']}
                type="button"
                onClick={close}
                aria-label="Close Navigation"
              >
                <Icon icon="remove" />
                <span className={styles['header-button-label']}>Menu</span>
              </button>
            </div>
            <nav>
              <ul className={styles['list']}>
                <li>
                  <Link exact="true" to="/" onClick={close}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className={styles['disabled']}
                    disabled
                    exact="true"
                    to="/blog"
                    onClick={(e) => e.preventDefault()}
                    tabIndex="-1"
                  >
                    Blog <span>(Soon)</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={styles['disabled']}
                    disabled
                    exact="true"
                    to="/work"
                    onClick={(e) => e.preventDefault()}
                    tabIndex="-1"
                  >
                    Work <span>(Soon)</span>
                  </Link>
                </li>
                <li>
                  <Link exact="true" to="/contact" onClick={close}>
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </Overlay>
    );
  }
}

export default Nav;
