import { Component } from 'react';
import ReactDOM from 'react-dom';

let portalContainer;

class Portal extends Component {
  constructor() {
    super();

    if (!portalContainer) {
      portalContainer = document.createElement('div');
      portalContainer.setAttribute('portal', '');
      document.body.appendChild(portalContainer);
    }

    this.el = document.createElement('div');
    portalContainer.appendChild(this.el);
  }

  componentWillUnmount() {
    portalContainer.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default Portal;
