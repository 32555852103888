import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/performance';
import config from 'config';

firebase.initializeApp(config);
firebase.analytics();
firebase.performance();

export default firebase;
