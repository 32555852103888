import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ContactPage from 'pages/contact';
import HelloPage from 'pages/hello';

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={HelloPage} />
      <Route path="/contact" exact component={ContactPage} />
      <Redirect to="/" />
    </Switch>
  );
}

export default Routes;
