import React from 'react';
import Page from 'components/page';
import { HELLO_PAGE_TITLE, HELLO_PAGE_DESCRIPTION } from 'utils/constants';

function HelloPage() {
  return (
    <Page title={HELLO_PAGE_TITLE} description={HELLO_PAGE_DESCRIPTION}>
      <h1>{HELLO_PAGE_TITLE}</h1>
      <p>
        I'm Dylan McDonald, a Twin Cities based full stack developer. Currently,
        as a full time lead developer, I spend my days developing innovative
        products and web solutions with incredible people at{' '}
        <a href="https://bswing.com/" rel="noopener noreferrer" target="_blank">
          bswing
        </a>
        . I've been with the company since graduating, with a Bachelor of
        Business Administration majoring in Management Information System, from
        UMD in 2010.
      </p>
      <p>
        In the warmer months I enjoy cabin/lake life, motorcycling, kayaking,
        and golfing. During the winter, the only thing that changes is the
        layers of clothing as I still try to get out as much as possible
        snowboarding, playing pick-up hockey, and ice fishing.
      </p>
      <br />
      <br />
      <h2>Latest Work</h2>
      <p>
        <i>(Soon)</i>
      </p>
    </Page>
  );
}

export default HelloPage;
