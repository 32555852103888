import React, { Component } from 'react';
import cn from 'classnames';
import { APP_NAME } from 'utils/constants';
import Equalizer from 'components/equalizer';
import Icon from 'components/icon';
import styles from './footer.module.css';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      online: false,
      song: ''
    };
  }

  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      fetch(process.env.REACT_APP_API_PLAYING_ENDPOINT)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          this.setState({
            online: data.online,
            song: `${data.artist} - ${data.name}`
          });
        });
    }
  }

  render() {
    const { online, song } = this.state;
    return (
      <div className={styles.wrapper}>
        <div className={styles.social}>
          <a
            className={cn(styles.audio, {
              [styles.online]: online
            })}
            href="https://www.last.fm/user/dylanjmcdonald"
            rel="noopener noreferrer"
            target="_blank"
            title={song}
          >
            <Equalizer active={online} />
            <div className={styles.song}>
              {(song && song) || 'Loading scrobble'}
            </div>
          </a>
          <div className={styles.brands}>
            <a
              href="https://open.spotify.com/user/dylanjmcdonald"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon icon="logo-spotify" />
            </a>
            <a
              href="https://github.com/dylanjmcdonald"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon icon="logo-github" />
            </a>
            <a
              href="https://www.instagram.com/dylanjmcdonald"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon icon="logo-instagram" />
            </a>
            <a
              href="https://twitter.com/dylanjmcdonald"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon icon="logo-twitter" />
            </a>
            <a
              href="https://www.linkedin.com/in/dylanjmcdonald"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon icon="logo-linkedin" />
            </a>
          </div>
        </div>
        <div className={styles.copyright}>
          &copy; {new Date().getUTCFullYear()} {APP_NAME}
        </div>
      </div>
    );
  }
}

export default Footer;
