import React, { Component, Fragment } from 'react';
import { Transition } from 'react-transition-group';
import Portal from 'components/portal';
import styles from './overlay.module.css';

class Overlay extends Component {
  static defaultProps = {
    onExit: () => {},
    onExiting: () => {},
    onExited: () => {},
    onEnter: () => {},
    onEntering: () => {},
    onEntered: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      exiting: false,
      exited: !props.isShown
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isShown && this.props.isShown) {
      this.setState({
        exited: false
      });
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.onEsc, false);
  }

  close = () => {
    this.setState({ exiting: true });
  };

  handleEntering = (node) => {
    document.body.addEventListener('keydown', this.onKeyDown, false);
    document.body.classList.add('no-scroll');
    this.props.onEntering(node);
  };

  handleEntered = (node) => {
    this.previousActiveElement = document.activeElement;
    this.props.onEntered(node);
  };

  handleExiting = (node) => {
    document.body.removeEventListener('keydown', this.onEsc, false);
    document.body.classList.remove('no-scroll');
    this.props.onExiting(node);
  };

  handleExited = (node) => {
    this.setState({ exiting: false, exited: true });
    this.props.onExited(node);
  };

  onKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.close();
    }
  };

  render() {
    const { isShown, children, onExit, onEnter } = this.props;
    const { exiting, exited } = this.state;

    if (exited) return null;

    const overlay = (
      <Transition
        appear
        unmountOnExit
        timeout={240}
        in={isShown && !exiting}
        onExit={onExit}
        onExiting={this.handleExiting}
        onExited={this.handleExited}
        onEnter={onEnter}
        onEntering={this.handleEntering}
        onEntered={this.handleEntered}
      >
        {(state) => (
          <div className={styles['wrapper']} data-state={state}>
            {typeof children === 'function'
              ? children({
                  state,
                  close: this.close
                })
              : children}
          </div>
        )}
      </Transition>
    );

    return (
      <Fragment>
        <Portal>{overlay}</Portal>
      </Fragment>
    );
  }
}

export default Overlay;
