import React from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'utils/constants';
import styles from './page.module.css';

function Page(props) {
  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>
          {APP_NAME} – {props.title}
        </title>
        <meta name="description" content={props.description} />
      </Helmet>
      {props.children}
    </div>
  );
}

export default Page;
