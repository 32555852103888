import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/logo@2x.png';
import Icon from 'components/icon';
import Nav from 'components/nav';
import { APP_NAME } from 'utils/constants';
import styles from './header.module.css';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = { navIsShown: false };
  }

  handleMenuButtonClick = () => {
    this.setState({ navIsShown: true });
  };

  handleNavOnCloseComplete = () => {
    this.setState({ navIsShown: false });
  };

  render() {
    const { navIsShown } = this.state;
    return (
      <div className={styles['wrapper']}>
        <div className={styles['section-left']}>
          <Link exact="true" to="/" className={styles['logo']}>
            <img src={logo} alt="brand logo" />
            <div className={styles['brand']}>{APP_NAME}</div>
          </Link>
        </div>
        <div className={styles['section-right']}>
          <button
            className={styles['menu-button']}
            type="button"
            onClick={this.handleMenuButtonClick}
            aria-label="Open Navigation"
          >
            <Icon icon="menu" />
            <span className={styles['menu-button-label']}>Menu</span>
          </button>
          <Nav
            isShown={navIsShown}
            onCloseComplete={this.handleNavOnCloseComplete}
          />
        </div>
      </div>
    );
  }
}

export default Header;
