import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';
import Header from 'components/header';
import Footer from 'components/footer';
import styles from './app.module.css';

function App() {
  return (
    <div className={styles.wrapper}>
      <BrowserRouter>
        <Header />
        <Routes />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
