import React from 'react';
import styles from './alert.module.css';
import Icon from 'components/icon';

function Alert(props) {
  return (
    <div className={styles['wrapper']}>
      {props.icon && <Icon icon={props.icon} />}
      <span>{props.children}</span>
    </div>
  );
}

export default Alert;
